import React from 'react'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'

import PropTypes from 'prop-types'

import rightButton from '../../images/right_button.svg'
import leftButton from '../../images/left_button.svg'

import * as S from './styles'
import 'pure-react-carousel/dist/react-carousel.es.css'

const Carousel = ({ title, description, images }) => (
  <S.Container>
    <S.Title>{title}</S.Title>
    <S.Description>{description}</S.Description>
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={100}
      totalSlides={images.length}
      isPlaying
      infinite
      isIntrinsicHeight
    >
      <Slider>
        {images.map((image, index) => (
          <Slide index={index}>
            <S.Image src={image.default} alt={`Preview for image ${index}`} />
          </Slide>
        ))}
      </Slider>

      <S.ActionWrapper>
        <S.Back>
          <img src={leftButton} />
        </S.Back>
        <S.Next>
          <img src={rightButton} />
        </S.Next>
      </S.ActionWrapper>

      <DotGroup />
    </CarouselProvider>
  </S.Container>
)

Carousel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

Carousel.defaultProps = {
  description: '',
}

export default Carousel
