import React from 'react'
import SectionTitle from '../SectionTitle'

import previewOurProcess from '../../images/our_process.svg'
import previewDiscover from '../../images/discover.svg'
import previewDefine from '../../images/define.svg'
import previewDecide from '../../images/decide.svg'
import previewPrototype from '../../images/prototype.svg'
import previewEvolve from '../../images/evolve.svg'

import * as S from './styles'

const Process = () => (
  <S.Section>
    <SectionTitle>Our Process</SectionTitle>
    <S.Preview src={previewOurProcess} alt="GNAR process" />
    <S.MobilePreview src={previewDiscover} alt="GNAR process - Discover" />
    <S.MobilePreview src={previewDefine} alt="GNAR process - Define" />
    <S.MobilePreview src={previewDecide} alt="GNAR process - Decide" />
    <S.MobilePreview src={previewPrototype} alt="GNAR process - Prototype" />
    <S.MobilePreview src={previewEvolve} alt="GNAR process - Evolve" />
  </S.Section>
)

export default Process
