import styled, { css } from 'styled-components/macro'
import { showsUp } from '../../styles/animations'
import { defaultMaxWidth } from '../../styles/global'

const sharedAnimation = css`
  transition: 0.2s all;
  opacity: 0;
  animation: ${showsUp} 0.5s ease forwards;
`

export const Box = styled.div`
  ${sharedAnimation}
  width: 50%;
  display: none;

  @media (max-width: 490px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  ${(props) =>
    `background: var(--landing-${props.backgroundColor}, --background-primary);`}
`

export const Container = styled.section`
  padding: ${(props) => (props.isShort ? '80px' : '0 80px 80px')};
  display: flex;
  align-items: center;
  height: ${(props) => (props.isShort ? '100%' : '100vh')};
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 60px 25px;
    flex-direction: column;
    height: 100%;
  }

  ${(props) =>
    props.isVisible &&
    `
    ${Box} {
      display: block;

      :nth-child(1) {
        animation-delay: .2s;
      }

      :nth-child(2) {
        animation-delay: .4s;
      }
    }
  `}
`

export const Image = styled.img`
  width: 100%;
  object-fit: contain;

  @media (max-width: 490px) {
    margin-top: 30px;
  }
`

export const VideoContainer = styled.div`
  position: relative;
`

export const Video = styled.video`
  width: 100%;

  @media (max-width: 490px) {
    margin-top: 30px;
  }
`

export const SubVideo = styled.video`
  width: 50%;
  position: absolute;
  bottom: -10%;
  left: -10%;

  @media (max-width: 490px) {
    bottom: -10%;
    left: -5%;
  }
`

export const Text = styled.span`
  display: block;

  ${(props) => props.mediumTitle && `font-size: 26px;`}

  ${(props) =>
    props.bigTitle &&
    `
    font-size: ${props.showcase ? '65px' : '40px'}; 
    font-family: var(--font-bold);
  `}

  ${(props) =>
    props.descriptionTitle &&
    `
    margin-top: 100px;
    font-family: var(--font-bold);
  `}
  
  ${(props) =>
    props.description &&
    `
    font-size: 16px;
    font-family: var(--font-light);
    margin: 20px 0 30px;
    line-height: 1.6;
    width: 65%;

    @media (max-width: 490px) {
      width: 100%;
    }
  `}

${(props) => props.isShort && 'width: 100%;'}

  ${(props) =>
    props.smallDescription &&
    `font-size: 14px;
    font-family: var(--font-mono);
    margin-bottom: 5px;
  `}
`

export const SvgContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.$halfSizeImage &&
    `
    width: 60%;
    margin: 0 auto;
  `}

  @media (max-width: 490px) {
    height: 100%;
  }
`
