import styled, { css } from 'styled-components/macro'
import { Link } from 'gatsby'
import { defaultMaxWidth } from '../../styles/global'

const menuShareStyle = css`
  text-decoration: none;
  border: none;
  display: block;
  color: var(--primary);
  background-color: var(--background-primary);
  width: fit-content;
  transition: 0.2s all ease;
  cursor: pointer;

  :hover {
    color: var(--highlight);
  }

  @media screen and (max-width: 490px) {
    margin: 0 auto;
  }
`

export const Container = styled.nav`
  display: flex;
  align-items: center;
  padding: 40px 80px 0;
  width: 100%;
  justify-content: space-between;
  background: transparent;
  height: 83px;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 25px;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: fit-content;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  outline: none;

  :hover {
    cursor: pointer;
  }
`

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  z-index: 99999999;
  overflow: hidden;

  @media (max-width: 490px) {
    padding-top: 40px;
  }
`

export const CloseButton = styled.div`
  ${menuShareStyle}
  font-size: 1.4rem;
  font-family: var(--font-light);
  border: 1px solid;
  border-radius: 6px;
  padding: 0 10px 4px;
  margin-left: calc(100% - 110px);
  margin-top: 40px;
`

export const MenuItem = styled(Link)`
  ${menuShareStyle}
  font-family: var(--font-bold);
  padding: 10px 20px;
  font-size: 3rem;
  margin-left: 80px;
  position: relative;

  ${(props) =>
    props.isActive &&
    `
    pointer-events: none;
    
    ::after {
      content: '';
      width: 40%;
      height: 5px;
      background-color: var(--highlight);
      bottom: 0;
      left: 20px;
      position: absolute;
    }
  `}
`

export const MenuContent = styled.div`
  display: block;
  text-align: center;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const HamburgerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: 25px;
  height: 25px;
  transform: rotate(90deg);
  transition: all ease 0.2s;
  z-index: 400;
  outline: none;

  &:hover {
    span:last-child {
      height: 20px;
      background-color: var(--primary);
    }

    span:first-child {
      height: 10px;
      background-color: var(--highlight);
    }
  }

  & span {
    &:first-child {
      right: 0px;
    }

    &:last-child {
      background-color: var(--highlight);
      height: 10px;
      right: 0px;

      &:hover {
        height: 20px;
        background-color: var(--primary);
      }
    }
  }
`

export const HamburgerSpan = styled.span`
  width: 2px;
  height: 20px;
  background: var(--primary);
  display: block;
  margin-right: 6px;
  position: relative;
  transition: all ease 0.2s;
`
