import React from 'react'

import * as S from './styles'

const IFrame = ({ url }) => (
  <S.Wrapper>
    <S.Section>
      <S.Text>Preview</S.Text>
      <S.Iframe
        src={url}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </S.Section>
  </S.Wrapper>
)

export default IFrame
