import styled from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'

export const Section = styled.section`
  padding: 40px 80px;
  position: relative;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 0 25px;
  }
`

export const Preview = styled.img`
  width: 85%;
  margin: 0 auto;
  display: block;

  @media screen and (max-width: 490px) {
    display: none;
  }
`

export const MobilePreview = styled.img`
  width: 80%;
  margin: 0 auto;
  display: block;

  @media screen and (min-width: 491px) {
    display: none;
  }
`
