import styled from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'

export const Container = styled.section`
  padding: 80px;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 40px 25px;
  }
`

export const Title = styled.h1`
  font-family: var(--font-bold);
  font-size: 26px;
  margin-bottom: 30px;
`

export const Description = styled.p`
  font-family: var(--font-light);
  width: 40%;
  margin: 0;
  line-height: 1.6;

  @media (max-width: 490px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 490px) {
    flex-wrap: wrap;
  }
`

export const Box = styled.div`
  padding: 20px 35px;
  width: 30%;
  background-color: var(--suplement-background);
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-size: 25%;

  @media (max-width: 490px) {
    width: 100%;
    margin: 0 0 25px;
  }
`

export const BoxTitle = styled.span`
  display: block;
  font-family: var(--font-light);
  font-size: 14px;
`

export const BoxValue = styled.span`
  display: block;
  font-family: var(--font-bold);
  font-size: 65px;
`
