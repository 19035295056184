import styled, { css } from 'styled-components/macro'
import { showsUp, emerge, bounce } from '../../styles/animations'
import { defaultMaxWidth } from '../../styles/global'

const sharedAnimation = css`
  transition: 0.2s all;
  opacity: 0;
  animation: ${showsUp} 0.5s ease forwards;
`

export const Container = styled.section`
  padding: 0 80px 100px;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  height: calc(100vh - 80px);
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }

  ${(props) =>
    props.hasLoadingScreenActive &&
    `

  @keyframes fadeIn {
    to { opacity: 1 };
  }

  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
  animation-delay: 3s;
  `}

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
  animation-delay: 0.65s;
`

export const Box = styled.div`
  ${(props) =>
    props.welcomeImage &&
    `

    @media (max-width: 490px) {
      width: 100%;
      translateY(-100px)
      padding: 0;
    }
  `}
`

export const Title = styled.a`
  ${sharedAnimation}

  text-decoration: none;
  display: block;
  padding: 0;
  line-height: 6.5vw;
  color: var(--primary);
  font-size: 4.5vw;
  font-family: var(--font-bold);
  width: fit-content;
  position: relative;

  &:nth-child(1) {
    animation-delay: 0.4s;
  }

  &:nth-child(2) {
    animation-delay: 0.6s;
  }

  &:nth-child(3) {
    animation-delay: 0.8s;
  }

  &:first-of-type {
    padding-top: 40px;
  }

  &:hover {
    color: var(--highlight);
    cursor: pointer;
  }

  &:after {
    content: '';
    width: 0.9vw;
    height: 0.9vw;
    position: absolute;
    right: -20px;
    bottom: 22px;
    border-radius: 50%;
    background: var(--highlight);
  }

  @media screen and (max-width: 490px) {
    font-size: 7vw;
    margin: 0;
    line-height: 36px;

    &:after {
      width: 1.5vw;
      height: 1.5vw;
      right: -15px;
      bottom: 8px;
    }
  }
`

export const About = styled.span`
  ${sharedAnimation}

  color: var(--primary);
  margin: 20px 0 40px;
  font-size: 1.4rem;
  width: 30rem;
  display: block;
  cursor: default;
  animation-delay: 1s;
  display: flex;
  align-items: center;
  line-height: 1.4;
  font-family: var(--font-light);

  @media screen and (max-width: 490px) {
    font-size: 4vw;
    line-height: 5vw;
    width: 90%;
    margin: 15px 0;
  }

  ${(props) => props.last && `margin-top: 40px`};
`

export const ActionWrapper = styled.div`
  ${sharedAnimation}

  display: flex;
  align-items: center;
  width: 100%;
  animation-delay: 1.2s;

  @media screen and (max-width: 490px) {
    margin: 50px 0;
    justify-content: flex-start;
  }
`

export const Image = styled.img`
  height: 1rem;
  margin-left: 15px;
  transition: 0.2s all;
  animation: ${bounce} 2s ease infinite;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: var(--primary);
  background: transparent;
  border: none;
  font-size: 1rem;
  font-family: var(--font-mono);
  transition: 0.2s ease all;

  &:hover {
    cursor: pointer;
    color: var(--highlight);
  }

  @media screen and (max-width: 490px) {
    width: 100%;
    text-align: right;
  }
`

export const Emerge = styled.div`
  transition: 0.2s all;
  opacity: 0;
  animation: ${emerge} 2s ease-out forwards;

  @media (max-width: 490px) {
    margin-top: -200px;
  }
`

export const Anchor = styled.a`
  margin-left: 10px;
  font-weight: bold;
  text-decoration: none;
  color: var(--primary);

  :hover {
    color: var(--highlight);
  }
`
