import React from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie-player'

import { lottieConfig } from '../../utils'

import DashboardSvg from '../../images/dashboard'
import ProjectsSvg from '../../images/projects'
import StatusSvg from '../../images/status'
import RemindersSvg from '../../images/reminders'

import * as S from './styles'

function getSvg(svg) {
  return {
    dashboard: <DashboardSvg />,
    projects: <ProjectsSvg />,
    status: <StatusSvg />,
    reminders: <RemindersSvg />,
  }[svg]
}

const Showcase = ({
  title,
  description,
  image,
  direction,
  lottieAnimation,
  titleDescription,
  halfSizeImage,
  svg,
}) => {
  return (
    <S.OuterContainer direction={direction}>
      <S.Container direction={direction}>
        <S.Wrapper>
          {lottieAnimation && (
            <Lottie
              {...lottieConfig({ animationData: lottieAnimation, loop: true })}
            />
          )}
          {image && (
            <S.Image
              src={image}
              alt={`Preview for ${title}`}
              $halfSizeImage={halfSizeImage}
            />
          )}
          {svg && (
            <S.SvgContainer $halfSizeImage={halfSizeImage}>
              {getSvg(svg)}
            </S.SvgContainer>
          )}
        </S.Wrapper>
        <S.Wrapper>
          <S.TitleDescription>{titleDescription}</S.TitleDescription>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.Wrapper>
      </S.Container>
    </S.OuterContainer>
  )
}

Showcase.propTypes = {
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['normal', 'inverted']),
  halfSizeImage: PropTypes.bool,
}

Showcase.defaultProps = {
  direction: 'normal',
  titleDescription: null,
  halfSizeImage: false,
}

export default Showcase
