import { createGlobalStyle, css } from 'styled-components'

export const defaultMaxWidth = css`
  @media screen and (min-width: 1680px) {
    width: 90%;
    margin: 0 auto;
  }
`

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #__next {
    height: 100%;
    margin: 0;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--background-primary);
    color: var(--primary);
    font-family: var(--font-regular);
  }

  p::selection,
  h1::selection,
  h2::selection,
  h3::selection,
  h4::selection,
  span::selection,
  a::selection {
    background-color: var(--highlight);
    color: var(--background-primary);
  }

  img {
    user-select: none;
  }
`

export default GlobalStyles
