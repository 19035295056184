import styled from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'

export const Wrapper = styled.div`
  background: var(--suplement-background);
`

export const Section = styled.div`
  padding: 80px;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 60px 25px;
  }
`

export const Text = styled.p`
  font-size: 26px;
  margin-bottom: 80px;
  font-family: var(--font-bold);
`

export const Iframe = styled.iframe`
  height: 680px;
  width: 100%;
  border: none;
  margin: 0 auto;
  display: block;

  @media (max-width: 490px) {
    width: 100%;
    margin: 20px auto;
    height: 180px;
  }
`
