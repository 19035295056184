import React from 'react'

import SectionTitle from '../SectionTitle'

import previewHeroku from '../../images/heroku.png'
import previewCodeish from '../../images/codeish.png'

import * as S from './styles'

const pressInformation = [
  {
    title: 'Heroku Article',
    description: 'GNAR is using Heroku for faster development',
    image: previewHeroku,
    href: 'https://www.heroku.com/customers/gnar',
  },
  {
    title: 'Code(ish) Podcast',
    description: 'GNAR is using a IoT platform to moniter rail yards',
    image: previewCodeish,
    href: 'https://www.heroku.com/podcasts/codeish/88-monitoring-productivity-through-iot',
  },
  {
    title: 'Heroku Article',
    description:
      'GNAR is transforming heavy industries using modern technology',
    image: previewHeroku,
    href: 'https://blog.heroku.com/transform-a-heavy-industry',
  },
]

const Press = () => (
  <S.Container>
    <SectionTitle>Press</SectionTitle>
    <S.InnerContainer>
      {pressInformation.map((details) => (
        <S.ClickableBox href={details.href} target="_blank" key={details.href}>
          <S.Image src={details.image} />
          <S.Title>{details.title}</S.Title>
          <S.Description>{details.description}</S.Description>
        </S.ClickableBox>
      ))}
    </S.InnerContainer>
  </S.Container>
)

export default Press
