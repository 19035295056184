import styled from 'styled-components'
import { defaultMaxWidth } from '../../styles/global'

export const Section = styled.section`
  height: 100%;
  padding: 0 80px 60px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 20px;
  }
`

export const GridContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 80px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-around;

  @media (max-width: 490px) {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

export const Wrapper = styled.div`
  margin-top: 60px;
  height: 575px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 490px) {
    width: 100%;
    margin-top: 0;
  }

  &:last-of-type p {
    max-width: 315px;
  }

  :nth-child(1) {
    animation-delay: 0.2s;
  }

  :nth-child(2) {
    animation-delay: 0.6s;

    > div {
      margin-top: -70px;
    }
  }

  :nth-child(3) {
    animation-delay: 0.8s;
  }
`

export const Title = styled.p`
  font-size: 1.5rem;
  margin: 0 0 15px;
  color: var(--primary);
  font-family: var(--font-bold);

  @media (max-width: 490px) {
    font-size: 26px;
  }
`

export const Image = styled.img`
  height: 24vw;
  margin: 10px 0;

  @media screen and (max-width: 490px) {
    height: 90vw;
  }
`

export const Description = styled.p`
  font-size: 1rem;
  color: var(--primary);
  max-width: 370px;
  font-family: var(--font-light);
  height: 80px;
  line-height: 1.4;

  @media screen and (max-width: 490px) {
    font-size: 16px;
    line-height: 5vw;
    max-width: 90%;
  }
`
