import React from 'react'

import Header from '../Header'
import Footer from '../Footer'

import GlobalStyles from '../../styles/global'
import '../../styles/fonts.css'
import '../../styles/colors.css'

const Layout = ({ children, location }) => {
  if (typeof window !== 'undefined') {
    window.addEventListener('load', function () {
      console.log("It's loaded!")
    })
  }

  return (
    <div>
      <GlobalStyles />
      <Header location={location} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
