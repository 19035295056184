import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import GlobalStyles from '../../styles/global'

import { lottieConfig } from '../../utils'

import * as S from './styles'

const LoadScreen = () => {
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import('../../assets/lottie/logo.json').then(setAnimationData)
  }, [])

  return (
    <S.Container>
      <GlobalStyles />
      {animationData && (
        <Lottie
          {...lottieConfig({ animationData })}
          style={{ height: 62, width: 200 }}
          speed={0.6}
        />
      )}
    </S.Container>
  )
}

export default LoadScreen
