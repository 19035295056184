import styled from 'styled-components/macro'
import { slideOut } from '../../styles/animations'

export const Container = styled.div`
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  animation: ${slideOut} 0.5s ease forwards;
  animation-delay: 6s;
`
