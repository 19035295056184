import styled from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'

export const OuterContainer = styled.div`
  background-color: var(
    ${(props) =>
      props.direction === 'normal'
        ? '--background-primary'
        : '--suplement-background'}
  );
`

export const Container = styled.section`
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: ${(props) =>
    props.direction === 'normal' ? 'row' : 'row-reverse'};
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 60px 25px;
    flex-wrap: wrap;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Container} & {
    :first-of-type {
      width: 50%;

      @media (max-width: 490px) {
        width: 100%;
        margin: 10px 0;
      }
    }

    :last-of-type {
      width: 40%;

      @media (max-width: 490px) {
        width: 100%;
        margin: 10px 0;
      }
    }
  }
`

export const Image = styled.img`
  width: 100%;

  ${(props) =>
    props.$halfSizeImage &&
    `
    width: 50%;
    margin: 0 auto;
  `}

  @media (max-width: 490px) {
    height: 100%;
  }
`
export const SvgContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.$halfSizeImage &&
    `
    width: 60%;
    margin: 0 auto;
  `}

  @media (max-width: 490px) {
    height: 100%;
  }
`

export const Title = styled.h2`
  font-size: 26px;
  width: 100%;
  margin-bottom: 30px;
  font-family: var(--font-bold);
`

export const Description = styled.p`
  line-height: 1.6;
  font-size: 16px;
  width: 100%;
  padding-right: 15%;
  font-family: var(--font-light);
`

export const TitleDescription = styled.p`
  font-size: 14px;
  font-family: var(--font-mono);
  margin-bottom: 10px;
`
