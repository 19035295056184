import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import Montserrat200 from '../../assets/fonts/montserrat-v15-latin-200.woff2'
import Montserrat700 from '../../assets/fonts/montserrat-v15-latin-700.woff2'
import MontserratRegular from '../../assets/fonts/montserrat-v15-latin-regular.woff2'
import JetbrainsMonoRegular from '../../assets/fonts/jetbrains-mono-v6-latin-regular.woff2'

const SEO = ({
  title,
  description,
  image,
  imageAlt,
  socialTitle,
  meta = [],
}) => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      title={title}
      htmlAttributes={{ land: 'en' }}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        { name: 'description', content: metaDescription },
        { property: 'og:title', content: socialTitle || title },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://gnar.io' },
        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: metaDescription },
      ]
        .concat(
          image
            ? [
                { property: 'og:image', content: image },
                { property: 'og:image:alt', content: imageAlt || title },
                { property: 'twitter:image', content: image },
                { property: 'twitter:image:alt', content: imageAlt || title },
                { property: `twitter:card`, content: 'summary_large_image' },
              ]
            : [{ property: `twitter:card`, content: 'summary' }]
        )
        .concat(meta)}
    >
      <link
        rel="preload"
        as="font"
        href={MontserratRegular}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={Montserrat200}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={Montserrat700}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={JetbrainsMonoRegular}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

export default SEO
