import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const showsUp = keyframes`
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
`
export const showsUpOpaque = keyframes`
  0% {
      opacity: 0;
      transform: translateY(10vw);
  }
  100% {
    opacity: .75;
    transform: inherit;
  }
`

export const pulse = keyframes`
  50% {
    box-shadow: 0 0 0 20px rgba(90,153,212,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(90,153,212,0);
  }
`

export const bounce = keyframes`
  40% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
`

export const emerge = keyframes`
  0% {
    opacity: 0.00001;
    transform: translateY(400px);
  }
  79% {
    opacity: 0.00001;
    transform: translateY(50px);
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const slideOut = keyframes`
  to {
    z-index: -2;
    top: -100%
  }
`
