import styled from 'styled-components/macro'

export const CallToAction = styled.a`
  text-decoration: none;
  border: 2px solid;
  padding: 10px 40px;
  display: block;
  color: var(--primary);
  font-family: var(--font-bold);
  width: fit-content;
  transition: 0.2s all;
  margin: 15px 0 60px;

  :hover {
    background-color: var(--primary);
    color: var(--background-primary);
  }

  :active {
    background-color: var(--highlight);
    color: var(--primary);
  }
`
