import styled from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'

export const Container = styled.section`
  padding: 40px 80px 100px;
  height: 100%;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    padding: 0 25px;
  }
`

export const InnerContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 490px) {
    margin-top: 50px;
  }
`

export const ClickableBox = styled.a`
  text-decoration: none;
  color: var(--primary);
  width: 26%;
  transition: 0.2s all ease;

  :hover {
    color: var(--highlight);
  }

  @media (max-width: 490px) {
    width: 100%;
    margin: 0 0 60px;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Title = styled.span`
  display: block;
  font-size: 20px;
  font-family: var(--font-bold);
  margin: 20px 0;
`

export const Description = styled.span`
  display: block;
  font-size: 16px;
  font-family: var(--font-light);
`
