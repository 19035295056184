import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Button = ({ href, target, children }) => (
  <S.CallToAction href={href} target={target}>
    {children}
  </S.CallToAction>
)

Button.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  children: PropTypes.string.isRequired,
}

Button.defaultProps = {
  target: '_self',
}

export default Button
