import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie-player'
import { Waypoint } from 'react-waypoint'

import Button from '../Button'

import { lottieConfig } from '../../utils'

import ProblemSvg from '../../images/problem'

import * as S from './styles'

const CaseStudy = ({
  projectName,
  descriptionTitle,
  description,
  previewImage,
  backgroundColor,
  href,
  showcase,
  isShort,
  lottieAnimation,
  svg,
  video,
  subvideo,
  halfSizeImage,
}) => {
  const bigTitle = { bigTitle: true }
  const mediumTitle = { mediumTitle: true }
  const [isSectionVisible, setIsSectionVisible] = useState(false)

  const projectNameViewMode = showcase ? bigTitle : mediumTitle
  const descriptionTitleViewMode = showcase ? mediumTitle : bigTitle

  function handleScrollEnter() {
    setIsSectionVisible(true)
  }

  function getSvg(svg) {
    return {
      problem: <ProblemSvg />,
    }[svg]
  }

  return (
    <S.Wrapper backgroundColor={backgroundColor}>
      <S.Container isShort={isShort} isVisible={isSectionVisible}>
        <S.Box description>
          {!isShort && <S.Text smallDescription>CASE STUDY</S.Text>}
          <S.Text {...projectNameViewMode} showcase={showcase}>
            {projectName}
          </S.Text>
          {!isShort && (
            <S.Text {...descriptionTitleViewMode} descriptionTitle>
              {descriptionTitle}
            </S.Text>
          )}

          <S.Text description isShort={isShort}>
            {description}
          </S.Text>
          {href && <Button href={href}>Learn More</Button>}
        </S.Box>
        {!isShort && (
          <S.Box preview>
            {lottieAnimation && (
              <Lottie
                {...lottieConfig({ animationData: lottieAnimation })}
                style={{ height: 550 }}
              />
            )}
            {svg && (
              <S.SvgContainer $halfSizeImage={halfSizeImage}>
                {getSvg(svg)}
              </S.SvgContainer>
            )}
            {video && (
              <S.VideoContainer>
                <S.Video loop autoPlay muted playsInline>
                  <source src={video} type="video/mp4" />
                  <img src={previewImage} />
                </S.Video>
                {subvideo && (
                  <S.SubVideo loop autoPlay muted>
                    <source src={subvideo} type="video/mp4" />
                  </S.SubVideo>
                )}
              </S.VideoContainer>
            )}
            {!lottieAnimation && !video && !svg && (
              <S.Image
                src={previewImage}
                alt={`Preview image from ${projectName} project`}
              />
            )}
          </S.Box>
        )}
        <Waypoint onEnter={handleScrollEnter} />
      </S.Container>
    </S.Wrapper>
  )
}

CaseStudy.propTypes = {
  projectName: PropTypes.string.isRequired,
  descriptionTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  previewImage: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  href: PropTypes.string,
  showcase: PropTypes.bool,
  lottieAnimation: PropTypes.string,
  isShort: PropTypes.bool,
  svg: PropTypes.string,
}

CaseStudy.defaultProps = {
  showcase: false,
  href: '',
  backgroundColor: '',
  lottieAnimation: '',
  isShort: false,
  svg: '',
}

export default CaseStudy
