import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-content: center;
  padding: 40px 0;
  width: 100%;
  height: fit-content;
`

export const Title = styled.span`
  display: block;
  padding: 0;
  margin: 0;
  font-family: var(--font-bold);
  font-size: 2.5rem;
  cursor: default;
`
