import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player'

import { lottieConfig } from '../../utils'

import iconArrow from '../../images/arrow.svg'

import * as S from './styles'

const Hero = ({ hasLoadingScreenActive }) => {
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import('../../assets/lottie/hero.json').then(setAnimationData)
  }, [])

  return (
    <>
      <S.Container hasLoadingScreenActive={hasLoadingScreenActive}>
        <S.Box welcomeMessage>
          <S.Title href="/#imagine">Imagine</S.Title>
          <S.Title href="/#imagine">Measure</S.Title>
          <S.Title href="/#imagine">Transform</S.Title>
          <S.About>
            We make ideas with apps come to life 🚀.
          </S.About>
          <S.ActionWrapper>
            <S.StyledLink href="/#imagine">SEE HOW</S.StyledLink>
            <S.Image src={iconArrow} alt="Arrow icon" />
          </S.ActionWrapper>
        </S.Box>
        <S.Emerge>
          <S.Box welcomeImage>
            {animationData && (
              <Lottie
                {...lottieConfig({ animationData, loop: true })}
                style={{ height: 550 }}
              />
            )}
          </S.Box>
        </S.Emerge>
      </S.Container>
    </>
  )
}

export default Hero
