const lottieConfig = ({ animationData = {}, loop = false, play = true }) => ({
  loop,
  play,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
})

export default lottieConfig
