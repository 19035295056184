import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const SectionTitle = ({ children }) => (
  <S.Container>
    <S.Title>{children}</S.Title>
  </S.Container>
)

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionTitle
