import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player'
import { Waypoint } from 'react-waypoint'

import SectionTitle from '../SectionTitle'

import { lottieConfig } from '../../utils'

import * as S from './styles'

const WhatWeDo = () => {
  const [animationAutoPlay, setAnimationAutoPlay] = useState(false)
  const [strategyAnimationData, setStrategyAnimationData] = useState()
  const [operationsAnimationData, setOperationsAnimationData] = useState()
  const [technologyAnimationData, setTechnologyAnimationData] = useState()

  useEffect(() => {
    import('../../assets/lottie/strategy.json').then(setStrategyAnimationData)
    import('../../assets/lottie/operations.json').then(
      setOperationsAnimationData
    )
    import('../../assets/lottie/technology.json').then(
      setTechnologyAnimationData
    )
  }, [])

  function handleScrollEnter() {
    setAnimationAutoPlay(true)
  }

  return (
    <S.Section id="imagine">
      <SectionTitle>What We Do</SectionTitle>

      <S.GridContainer>
        <S.Wrapper>
          <Lottie
            {...lottieConfig({
              animationData: strategyAnimationData,
              loop: true,
              play: animationAutoPlay,
            })}
            style={{ height: 375, width: 350 }}
          />
          <S.Title>Strategy</S.Title>
          <S.Description>
            We strategize with businesses to develop better processes and run
            more efficiently.
          </S.Description>
        </S.Wrapper>

        <S.Wrapper taller>
          <Lottie
            {...lottieConfig({
              animationData: operationsAnimationData,
              loop: true,
              play: animationAutoPlay,
            })}
            style={{ height: 450, width: 350 }}
          />
          <S.Title>Operations</S.Title>
          <S.Description>
            We increase productivity by fine tuning operations and defining
            metrics
          </S.Description>
        </S.Wrapper>

        <S.Wrapper>
          <Lottie
            {...lottieConfig({
              animationData: technologyAnimationData,
              loop: true,
              play: animationAutoPlay,
            })}
            style={{ height: 375, width: 350 }}
          />
          <S.Title>Technology</S.Title>
          <S.Description>
            We develop and maintain tech to address business needs
          </S.Description>
        </S.Wrapper>
      </S.GridContainer>
      <Waypoint onEnter={handleScrollEnter} />
    </S.Section>
  )
}

export default WhatWeDo
