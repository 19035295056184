import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player'
import * as S from './styles'

import { lottieConfig } from '../../utils'

const Header = ({ location }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import('../../assets/lottie/logo.json').then(setAnimationData)
  }, [])

  const HamburgerIcon = () => (
    <S.HamburgerContainer>
      <S.HamburgerSpan />
      <S.HamburgerSpan />
      <S.HamburgerSpan />
    </S.HamburgerContainer>
  )

  const Menu = () => (
    <S.MenuContainer>
      <S.CloseButton onClick={() => setShowMenu(false)}>x</S.CloseButton>
      <S.MenuContent>
        <S.MenuItem
          to="/"
          onClick={() => setShowMenu(false)}
          isActive={location.pathname.length === 1}
        >
          Home
        </S.MenuItem>
        <S.MenuItem
          to="/slingshot"
          onClick={() => setShowMenu(false)}
          isActive={location.pathname.includes('slingshot')}
        >
          Slingshot
        </S.MenuItem>
        <S.MenuItem
          to="/intrmodl"
          onClick={() => setShowMenu(false)}
          isActive={location.pathname.includes('intrmodl')}
        >
          Intrmodl
        </S.MenuItem>
        <S.MenuItem
          to="/slateplan"
          onClick={() => setShowMenu(false)}
          isActive={location.pathname.includes('slateplan')}
        >
          Slateplan
        </S.MenuItem>
        <S.MenuItem
          to="/credits"
          onClick={() => setShowMenu(false)}
          isActive={location.pathname.includes('credits')}
        >
          Who we are
        </S.MenuItem>
      </S.MenuContent>
    </S.MenuContainer>
  )

  return (
    <S.Container>
      <S.StyledLink to="/">
        {animationData && (
          <Lottie
            {...lottieConfig({ animationData })}
            style={{ height: 42, width: 130 }}
          />
        )}
      </S.StyledLink>
      <S.Button onClick={() => setShowMenu(true)}>
        <HamburgerIcon />
      </S.Button>
      {showMenu && <Menu />}
    </S.Container>
  )
}

export default Header
