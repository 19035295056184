import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Metrics = ({ title, description, data }) => (
  <S.Container>
    <S.Title>{title}</S.Title>
    <S.Description>{description}</S.Description>
    <S.Wrapper>
      {data.map((info) => (
        <S.Box key={info.title} backgroundImage={info.backgroundIcon}>
          <S.BoxTitle>{info.title}</S.BoxTitle>
          <S.BoxValue>{info.value}</S.BoxValue>
        </S.Box>
      ))}
    </S.Wrapper>
  </S.Container>
)

Metrics.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
      backgroundIcon: PropTypes.string,
    })
  ).isRequired,
}

export default Metrics
