import styled, { css } from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'
import { showsUpOpaque, showsUp } from '../../styles/animations'

const sharedAnimation = css`
  transition: 0.2s all;
  opacity: 0;
  animation: ${showsUp} 0.5s ease forwards;
`

export const Container = styled.section`
  ${sharedAnimation}
  animation-delay: 1s;
  display: flex;
  flex-wrap: wrap;
  padding: 0 80px 30px;
  margin-bottom: 40px;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    flex-wrap: wrap;
    height: fit-content;
    padding: 0 25px;
  }
`

export const ImageWrapped = styled.div`
  width: calc(100% / 4);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  margin: 0 0 50px;

  @media screen and (max-width: 490px) {
    width: 100%;
    margin: 0 0 20px;
  }
`

export const Image = styled.img`
  height: 18vw;
  width: 18vw;
  object-fit: cover;
  position: relative;

  @media (max-width: 490px) {
    height: 65vw;
    width: 100%;
  }
`

export const Details = styled.div`
  width: 18vw;
  text-align: left;
  margin-top: 30px;

  @media (max-width: 490px) {
    width: 100%;
  }

  ${(props) =>
    props.flex &&
    `
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0 0;
    justify-content: space-between;
    max-width: 18vw;
  `}
`

export const PersonName = styled.p`
  margin: 0 0 5px;
  color: var(--primary);
  font-size: 21px;
  font-family: var(--font-bold);
`

export const PersonRole = styled.p`
  margin: 0 0 10px;
  color: var(--primary);
  font-size: 15px;
  font-family: var(--font-mono);
  opacity: 0.8;
`

export const SocialIcon = styled.a`
  margin: 10px 0 0;
  height: 20px;
`

export const Text = styled.span`
  display: block;
  width: 100%;
  padding-right: 50%;
  font-family: var(--font-light);
  line-height: 1.6;
  margin-bottom: 30px;

  @media (max-width: 490px) {
    padding-right: 0;
  }
`

export const Tag = styled.div`
  margin: 0;
  color: var(--primary);
  font-size: 14px;
  font-family: var(--font-mono);
  opacity: 0.8;
  background: var(--suplement-background);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px 8px;
  height: 22px;
  border-radius: 8px;
  white-space: nowrap;

  ${(props) =>
    props.flag &&
    `
  padding-right: 12px;
  `}
`

export const HiddenPhrase = styled.div`
  position: relative;
  :hover {
    :after {
      content: '${(props) => props.phrase}';
      width: calc(100% - 20%);
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--landing-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10%;
      text-align: center;
      line-height: 1.4;
      opacity: 0;
      animation: ${showsUpOpaque} 0.5s forwards;
      font-family: var(--font-mono);
    }
  }
`

export const Space = styled.p``
