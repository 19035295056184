import styled, { css } from 'styled-components/macro'
import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import { defaultMaxWidth } from '../../styles/global'

const sharedButton = css`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  margin-left: 20px;
  font-size: 26px;
  background: transparent;
  margin-top: 20px;
`

export const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 80px;
  ${defaultMaxWidth}

  & .carousel__dot-group {
    position: absolute;
    bottom: 40%;
    left: -15px;
    transform: rotate(90deg);

    @media (max-width: 490px) {
      top: 80%;
      transform: none;
      margin-left: 45px;
    }

    & .carousel__dot {
      height: 15px;
      width: 15px;
      margin: 0 20px;
      border-radius: 50%;
      border: none;
      background-color: #fff;

      &.carousel__dot--selected {
        border: 2px solid var(--highlight);
        background: transparent;
      }
    }
  }

  @media (max-width: 490px) {
    padding: 60px 25px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 60vh;
  object-fit: contain;
`

export const ActionWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 80px;

  @media (max-width: 490px) {
    text-align: left;
    padding: 0;
  }
`

export const Back = styled(ButtonBack)`
  ${sharedButton}
`

export const Next = styled(ButtonNext)`
  ${sharedButton}
`

export const Title = styled.p`
  font-size: 26px;
  margin-bottom: 30px;
  font-family: var(--font-bold);
`

export const Description = styled.p`
  font-family: var(--font-light);
  width: 40%;
  margin-bottom: 40px;
  line-height: 1.6;

  @media (max-width: 490px) {
    width: 100%;
  }
`
