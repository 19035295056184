import React from 'react'

import SectionTitle from '../SectionTitle'

import * as S from './styles'

const About = ({ crew, title }) => (
  <S.Container>
    <SectionTitle>{title}</SectionTitle>
    {crew.map((person) => (
      <S.ImageWrapped key={person.name}>
        <S.Details>
          <S.PersonName>{person.name}</S.PersonName>
          <S.PersonRole>{person.role}</S.PersonRole>
        </S.Details>
        <S.Image src={person.picture} />
        {person.description && person.nationality && (
          <S.Details flex>
            <S.Tag>{person.description}</S.Tag>
            <S.Tag flag>{person.nationality}</S.Tag>
          </S.Details>
        )}
      </S.ImageWrapped>
    ))}
  </S.Container>
)

export default About
