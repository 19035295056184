import styled from 'styled-components/macro'
import { defaultMaxWidth } from '../../styles/global'

export const Wrapper = styled.div`
  background-color: var(--suplement-background);
`

export const Container = styled.div`
  height: fit-content;
  padding: 60px 75px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${defaultMaxWidth}

  @media (max-width: 490px) {
    height: fit-content;
    padding: 50px 0;
    width: 100%;
    flex-direction: column-reverse;
  }
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-family: var(--font-regular);
  margin: 10px 0;
  color: var(--primary) !important;
  text-decoration: none;

  ${(props) => props.small && `font-size: 12px;`}

  ${(props) =>
    props.mono &&
    `
    margin: 0;
    font-size: 8px;
    letter-spacing: 2px;
  `}
`

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;
  margin-left: -20px;
`

export const SocialIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: 0 20px;
  object-fit: contain;
  transition: 0.2s all ease;

  :hover {
    transform: translateY(-3px);
  }
`

export const Anchor = styled.a`
  text-decoration: none;
  font-family: var(--font-bold);
  transition: 0.2s all;
  margin: 10px 0;
  color: var(--primary);

  :hover {
    color: var(--highlight);
  }
`

export const CallToAction = styled.a`
  text-decoration: none;
  border: 2px solid;
  padding: 10px 40px;
  display: block;
  color: var(--primary);
  font-family: var(--font-bold);
  width: fit-content;
  transition: 0.2s all;
  margin: 15px 0 60px;

  :hover {
    background-color: var(--background-primary);
    color: var(--highlight);
  }
`

export const LogoWrapper = styled.div`
  margin: none;
  margin-bottom: 60px;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  :first-of-type {
    width: 30%;

    @media (max-width: 490px) {
      width: calc(100% - 60px);
      margin: 0 auto;
    }
  }

  :last-of-type {
    width: 30%;

    @media (max-width: 490px) {
      width: calc(100% - 60px);
      margin: 0 auto 50px;
    }
  }
`

export const Logo = styled.img`
  margin: none !important;
  height: 42px;
  width: 120px;
  object-fit: contain;
`
